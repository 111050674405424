@use "sass:map";
@import "../../../Theme/ThemeLibrary";

.errorPage {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    h2.fa-solid {
        @include font-size(map.get($display-font-sizes, 1));
    }

    p {
        text-align: center;
    }
}