@import "../../../../../Theme/ThemeLibrary";

#navTopbar {
    .transaction {
        flex-grow: 2;
        
        @include media-breakpoint-down(sm) {
            display: none;
        }

        .breadcrumb, .innerBreadcrumb {
            margin: 0;
            user-select: none;
            width: fit-content;
            align-items: center;
            position: relative;
        }
    
        .breadcrumb {
            min-height: 35px;
            
            .breadcrumb-item + .breadcrumb-item::before {                
                color: $appbar-dark-text;
            }

            .breadcrumb-item:not(.id) {
                font-size: .8rem;
            }
    
            .id, .title {
                transform: translate3d(0, 0, 0);
            }
    
            .id {
                margin: 0;
                font-weight: $font-weight-bold;
            }
        }

        .innerBreadcrumb {
            padding: 0 0 0 $breadcrumb-item-padding-x;
            display: inline-flex;

            &:before{
                padding-right: $breadcrumb-item-padding-x;
                content: escape-svg($breadcrumb-divider);
            }
        }
    }
}