@import "../../../../../../Theme/ThemeLibrary";

.basicoChartWidget {
    border-color: transparent;
    box-shadow: 0 0 .125rem .125rem fade-out($black, 0.85);

    width: 100%;
    
    @include media-breakpoint-up(md) {
        width: 50%;
    }

    @include media-breakpoint-up(lg) {
        width: 33.3%;
    }

    @include media-breakpoint-up(xl) {
        width: 23%;
    }

    .k-card-title {
        margin: 0;
    }

    .k-card-body {
        display: flex;
        flex-direction: column;
    }
    
    .contentWrapper {
        display: flex;

        .chartWrapper {
            flex: 1 0 auto;
        }
        
        &.withAdded {
            .chartWrapper {
                flex: 0 0 40%;
            }
        }

        .addedContent {
            flex: 1 0 60%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &.clickable { cursor: pointer; }
}
