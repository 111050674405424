@use "sass:color";
@import "../../../Theme/ThemeLibrary";

.message.dialogWrap {

    .k-dialog-title {
        i {
            margin-right: .5rem;
            font-size: 1.65rem;
        }
    }

    .k-dialog-content {
        p {
            white-space: pre-line;
        }
    }

    &.smW .k-dialog {
        @media (max-height: 19.9375rem) { // 319px
            height: 100vh;
            max-height: none;
        }

        @media (min-height: 20rem) { // 320px
            min-height: 20rem;
            max-height: none;
        }
    }

    &.warning {
        .k-dialog {
            $borderColor: color.scale($warning, $saturation: -30%, $lightness: 65%);
            border-color: $borderColor;

            .k-dialog-titlebar {
                color: color.scale($warning, $lightness: -17%);
                font-weight: 600;
                border-bottom-color: $borderColor;
            }

            .k-actions { border-top-color: $borderColor; }
        }
    }

    &.error {
        .k-dialog {
            $borderColor: color.scale($danger, $saturation: -50%, $lightness: 80%);
            border-color: $borderColor;

            .k-dialog-titlebar {
                color: $danger;
                font-weight: 600;
                border-bottom-color: $borderColor;
            }

            .k-actions { border-top-color: $borderColor; }
        }
    }
}