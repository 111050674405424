
@import "../../../../Theme/ThemeLibrary";

#navTopbar {
  gap: 1rem;
  flex-wrap: nowrap;

  &.touch {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @include tmsTransition(#{"transform, background-color"});

  img {
    max-width: 2.75rem;
  }

  h1, h2, h3 {
    margin: 0;
  }

  h1 {
    @include font-size(2rem);    
    font-weight: 700;
    margin: -.125rem 0;
  }

  h2 { font-size: .8rem; }
  h3 { font-size: .7rem; }

  @each $fmt,$color in $FMTColors {
    &.#{$fmt} {
      $backFmtColor: scale-color($color, $lightness: -35%, $saturation: 50%);
      background: $backFmtColor;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .personCompanyName {
    flex-direction: column;
    max-width: 20rem;

    h2, h3 {
      @include ellipsisText();
      width: 100%;
      text-align: right;
    }

    h2 {
      font-weight: 600;
    }

    h3 {
      opacity: .65;
    }
  }

  .avatar {
    cursor: pointer;

    .k-avatar-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      .fa-regular {
        font-size: 1.5rem;
      }
    }
  }

  .k-badge-container {
    margin-right: 8px;
  }

  .menuUsuario {
    .k-button {
      img {
        height: 100%;
      }
    }
  }
}
