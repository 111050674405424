@use "sass:color";

@import "./layoutThemeFunctions";

@include mainLayout();

#root-tms {
	.content {
        & > main {
            grid-template-columns: auto minmax(0, 1fr);
        }
    }

    .overlay {
		display: none;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background: fade-out($black, 0.6);
		z-index: 98;
		opacity: 0;

		@include tmsTransition(#{"opacity, display"});

		&.active {
			display: block;
			opacity: 1;
		}
	}
}