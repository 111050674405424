@use "sass:math";
@import "../../../../Core/Layout/layoutThemeFunctions";

@include mainLayout();

#root-tms {
    .loginContent {
        position: relative;
        
        & > main {
            display: grid;
            grid-template-columns: auto minmax(0, 1fr);

            background-color: $white;
            @include tmsTransition(#{"background-color"});

            #navSidebar {
                position: absolute;
                top: 0;
                bottom: 0;

                transform-origin: left;
                transform: translateX(-13rem);
                @include tmsTransition(#{"transform"});
            }

            &.success {
                background-color: $gray-100;

                #navSidebar {
                    transform: translateX(0);
                }
            }
        }

        .loginHeader {
            position: absolute;
            width: 100%;

            transform-origin: top;
            transform: translateY(-4rem);
            @include tmsTransition(#{"transform"});

            &.success {
                transform: translateY(0);
                z-index: 2;
            }
        }
    }
}

#I010000 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

	// background: linear-gradient(-45deg, $gray-200, $white);
    // background-size: 100% 400%;

    opacity: 1;
    @include tmsTransition(#{"opacity"});

    &.success {
        opacity: 0;
    }

    .title {
        display: grid;
        grid-template-areas:
            "logo acronym"
            "logo name";
        align-items: center;
        column-gap: 0rem;
        margin-top: 0;
        color: $gray-500;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1;

        img {
            grid-area: logo;
            max-width: 250px; 
        }

        .acronym {
            grid-area: acronym;
            justify-self: center;
        }
        .name {
            grid-area: name;
            font-size: 1rem;
        }
    }

    $alturaContenido: 18rem;
    
    #FormContainer {
        width: 100vw;
        height: $alturaContenido;
        
        position: relative;
        overflow-x: hidden;

        .column {
            width: 100vw;

            align-items: center;
            justify-content: flex-start;

            position: absolute;
            top:0;
            
            transform-origin: left;
            transform: translateX(0);
            @include tmsTransition(#{"transform"});

            &.prev { transform: translateX(-100vw); }
            &.next { transform: translateX(100vw); }
        }

        .fa-regular, .fa-solid {
            font-size: 1.25rem;
        }

        .userPass, .twoFactor {
            padding-top: .5rem;
        }

        .userPass {
            height: $alturaContenido;
        }

        .twoFactor {
            height: 12rem;
        }

        #LoginUserPassForm, #Login2FAForm, #ModifyPasswordForm {            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .row {
                width: 18rem;
                align-items: center;
                justify-content: center;
            }

            .btnWrap {
                padding-top: calc( 1.5 * #{$font-size-base} );

                .k-button {
                    flex-direction: row-reverse;

                    .k-button-text {
                        display: inline-flex;
                        align-items: center;
                    }

                    .k-loader { margin-left: .5rem; }
                }
            }
        }

        #LoginUserPassForm {
            .row {                
                &:not(.passSubmit) .k-floating-label-container {
                    width: 100%;
                }

                &.passSubmit {
                    gap: 1rem;
                    flex-direction: row;
                    
                    .k-floating-label-container {
                        flex: 1 1 ;
                    }

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
    
                        .k-floating-label-container {
                            width: 100%;
                        }
    
                        .btnWrap {
                            width: 100%;
                            margin: 0;
    
                            .k-button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        #Login2FAForm, #ModifyPasswordForm {
            .row {
                .k-floating-label-container { flex-grow: 1; }
                .btnWrap { margin-left: 1rem; }

                @include media-breakpoint-down(md) {
                    flex-direction: column;

                    .k-floating-label-container {
                        width: 100%;
                    }

                    .btnWrap {
                        width: 100%;
                        margin: 0;

                        .k-button {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .switchLogMode {
            font-size: .75rem;
            font-weight: 600;
            margin: 0;
            padding: 1rem 0;
        }

        #ModifyPasswordForm {
            .passwordRules {
                font-size: .7rem;
                font-weight: 700;
                color: $primary;
            }
        }

        .wrap2FAPassword {
            #Login2FAForm {
                .row {
                    flex-direction: row;
                    
                    .k-floating-label-container, .btnWrap { width: auto; }

                    .btnWrap { margin-left: 1rem; }
                    
                    .k-button-text { display: none; }
                }
            }
        }

        .alert {
            margin: 1rem 0 0;
            padding: .5rem .75rem;
        }
    }

    .tms {
        margin-top: 2rem;
        height: 40px;
    }

    .letras {
        margin-top: 0.6rem;
        height: 20px;
        width: 250px;
    }

    .logo {
        height: 160px;
        margin-top: 2rem;
    }
}