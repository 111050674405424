@import "../../../../../Theme/ThemeLibrary";

#I000404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        margin: 0 0 1rem;
        padding: 0 1rem;
        text-align: center;
        color: $gray-900;
        
        @include font-size(1.75rem);
    }
    
    .icon {
        color: $gray-800;
        padding: 1rem;

        @include font-size(4.5rem);
    }

}