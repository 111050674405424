@use "sass:color";

// Importación de variables propias
@import "themeVariables";
@import "themeFunctions";

// Bootstrap overrides
$font-family-base: $FontFamilyBase;
$font-size-base: $FontSizeBase;

$legend-margin-bottom: .25rem;
$legend-font-size: 1rem;

$gray-100: $Gray1;
$gray-200: $Gray2;
$gray-300: $Gray3;
$gray-400: $Gray4;
$gray-500: $Gray5;
$gray-600: $Gray6;
$gray-700: $Gray7;
$gray-800: $Gray8;
$gray-900: $Gray9;

$primary: $PrimaryColor;
$secondary: $SecondaryColor;
$tertiary: $TertiaryColor;
$success: $OkColor;
$info: $InfoColor;
$warning: $WarningColor;
$danger: $KoColor;

$body-bg: $BackgroundColor;
$body-color: $TextColor;

$input-color: $TextColor;
$input-placeholder-color: $gray-400;

$link-decoration: none;

// $border-radius: .2rem;

$enable-shadows: true;
$enable-responsive-font-sizes: true;

$breadcrumb-bg: transparent;

$pagination-color: $PrimaryColor;
$pagination-active-bg: $PrimaryColor;

$table-accent-bg: rgba($gray-900, .015);
$table-color: $TextColor;

// Kendo overrides

// Generals
$font-family: $font-family-base;
// $disabled-filter: grayscale(.1) !default;
$disabled-opacity: .45;
// $default-scrollbar-width: .5rem;

// AppBar
$appbar-light-bg: scale-color($primary, $lightness: 95%, $saturation: -75%); //mix($gray-100, #598AB5, 99%); //$gray-100;
$appbar-box-shadow: 0 .125rem .125rem fade-out(black, .8) !default;
$appbar-light-text: $gray-600;
$appbar-dark-bg: $primary;
$appbar-dark-text: #FFF;
$appbar-padding-x: 1rem;

// Buttons
$kendo-button-bg: #FFF;

// Calendar
$calendar-week-number-text: $gray-300;

// Chips
$kendo-chip-font-size: .75rem;
$kendo-chip-line-height: $kendo-chip-font-size * 2;
$kendo-chip-padding-x: .375rem;
$kendo-chip-padding-y: 0;
$chip-icons-size: $kendo-chip-font-size * 1.5;
$kendo-chip-solid-bg: $gray-100;
$kendo-chip-solid-border: $gray-200;
$kendo-chip-solid-hover-bg: $gray-200;
$kendo-chip-solid-selected-bg: $gray-300;

// Dropdowns
$dropdownlist-bg: $body-bg;
$dropdownlist-hovered-bg: $body-bg;
$dropdownlist-focused-bg: $body-bg;
$dropdownlist-focused-border: mix(white, $primary, 50%);
$dropdownlist-focused-shadow: 0 0 0 .25rem rgba($primary, .25);

// DateTimePicker
$datetime-select-bg: $body-bg;
$datetime-select-border: transparent;

// // Grid
$grid-header-bg: #F3F3F3;
$grid-alt-bg: #F7F7F7;
$grid-border: transparent;
$grid-sorted-bg: rgba($body-bg, 0);
$grid-text: $body-color;
$pager-item-bg: transparent;
$pager-item-border: transparent;
$pager-number-selected-border: $pager-item-border;

// Input picker
$kendo-input-font-size-sm: .75rem;
$kendo-picker-text: $TextColor;
$kendo-picker-bg: $body-bg;
$kendo-picker-border: $gray-400;
$kendo-picker-hover-bg: $body-bg;
$kendo-picker-hover-border: $gray-400;
$kendo-picker-focus-bg: $body-bg;
$kendo-picker-focus-text: $body-color;
$kendo-picker-focus-border: mix(white, $primary, 50%);
$kendo-picker-focus-shadow: 0 0 0 .25rem rgba($primary, .25);

// List item
$list-item-padding-x: .5rem;
$list-item-padding-y: .25rem;

// Notification
$notification-padding-x: .85rem;
$notification-padding-y: .375rem;
$notification-font-size: .75rem;
$notification-icon-offset: 0;
$notification-shadow: 0 -.1875rem 0 white, 0 .125rem .125rem 0 fade-out(black, .85);

$numeric-button-bg: $body-bg;
$numeric-button-border: transparent;

$pager-item-border-radius: .2rem;

$kendo-menu-popup-item-focus-shadow: none;
$popup-bg: $gray-100;
$popup-shadow: 0 .125rem .25rem 0 fade-out(black, .85), 0 .25rem .3125rem 0 fade-out(black, .84);
// $popup-text: $TextColor;

$skeleton-text-border-radius: 0;
$skeleton-item-bg: rgba($gray-400, .3 );
$skeleton-wave-bg: rgba(black, .08 );

$tabstrip-content-padding-x: 1rem;
$tabstrip-content-padding-y: 1rem;
$tabstrip-content-text: $TextColor;
// $tabstrip-font-size: $font-size !default;
// $tabstrip-item-text: $gray-600;
$tabstrip-item-selected-text: $TextColor;
$tabstrip-wrapper-padding-y: 0.5rem;

$toolbar-bg: $body-bg;
$toolbar-border: $gray-200;
$toolbar-text: $gray-700;

$tooltip-max-width: 20rem;
$tooltip-border-width: 0.07rem;
$tooltip-error-bg: scale-color($danger, $lightness: 95%, $saturation: -60%);
$tooltip-error-text: scale-color($danger, $blackness: 20%);
$tooltip-error-border: scale-color($danger, $lightness: 65%, $saturation: -50%);

$tooltip-info-bg: scale-color($info, $lightness: 95%, $saturation: -60%);
$tooltip-info-text: scale-color($info, $blackness: 20%);
$tooltip-info-border: scale-color($info, $lightness: 65%, $saturation: -50%);

$window-shadow: 0 0 1rem rgba($gray-700, .5);