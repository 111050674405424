// Custom functions library

// Ellipsis text
@mixin ellipsisText(){            
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Transitions
@mixin tmsTransition($transitionProperties: all) {    
    will-change: $transitionProperties;
    
    transition-property: $transitionProperties; 
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

// Animations

@mixin tmsAnimation($name, $duration: 1.75s, $timingFunction: steps(1, start), $iterationCount: infinite) {
    -webkit-animation-name: $name;
    -webkit-animation-duration: $duration;
    -webkit-animation-timing-function: $timingFunction;
    -webkit-animation-iteration-count: $iterationCount;
    -moz-animation-name: $name;
    -moz-animation-duration: $duration;
    -moz-animation-timing-function: $timingFunction;
    -moz-animation-iteration-count: $iterationCount;
    animation-name: $name;
    animation-duration: $duration;
    animation-timing-function: $timingFunction;
    animation-iteration-count: $iterationCount;
}