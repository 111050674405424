@import "../../../../Theme/ThemeLibrary";

// Buscador
#navTopbar .finder {
    @include media-breakpoint-down(sm) {
        flex-grow: 1;
        flex-shrink: 1;
    }

    & > div {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 360px;

        .lar, .las { font-size: 1.25rem; }

        i, .k-button {
            position:absolute;
        }

        i {
            transform: translate3d(0.625rem, 0, 0); //10px
            color: $gray-400;
        }

        input {
            padding-left: 2.25rem;
            padding-right: 2rem;
        }

        .k-button {
            right: 0;
            height: 100%;
            aspect-ratio: 1;
            color: $gray-600;
        }

        .k-button:hover{
            background-color: grey;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            i {
                right: 0;
                transform: translate3d(-8.75rem, 0, 0); //-140px
            }

            input {
                opacity: 1;
                transform: scaleX(1);
                transform-origin: right;
            }
        }

        @include media-breakpoint-up(md) {
            i {
                transform: translate3d(-21rem, 0, 0); //-170px
            }
        }
    }
}