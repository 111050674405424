@use "sass:map";
@use "sass:color";

@import "../../../Theme/ThemeLibrary";

.dialogWrap {
    .k-dialog-title {
        align-items: center;

        .title { flex-grow: 1;}
        
        .dialogLoaderWrap {
            display: flex;
            align-items: center;
            padding-right: .5rem;
        }
    }

    .k-dialog {
        min-width: 20rem;
        width: 100%;

        @include media-breakpoint-down(md) {
            height: 100%;
        }

        @include media-breakpoint-up(sm) {
            min-width: 20rem;
        }
        
        @include media-breakpoint-up(md) {
            width: fit-content;
        }
        
        @media (max-height: 29.9375rem) { // 479px
            height: 100vh;
            max-height: none;
        }
    }

    &:not(.selectorDialog) {
        .k-dialog {
            @media (min-width: map.get($grid-breakpoints, "sm")) and (min-height: 30rem) {
                max-height: 90vh;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &.smW {
            .k-dialog {
                width: 25rem; //breakpoint-min(sm);
            } 
        }
    
        &.mdW {
            .k-dialog {
                width: breakpoint-min(md);
            } 
        }

        &.lgW {
            .k-dialog {
                width: 100%;
            } 
        }
    }

    @include media-breakpoint-up(xl) {        
        &.lgW {
            .k-dialog {
                width: 95vw;
            } 
        }
    }

    @media (min-height: 30rem) { // 480px
        &.smH .k-dialog {
            min-height: 20rem; // 320px;
            height: 37.5vh;
            max-height: 23rem; // 368px;
        }
        &.mdH .k-dialog { height: 65vh; }
        &.lgH .k-dialog { height: 95vh; }
    }

    .blocked {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: fade-out($body-bg, 0.7);
        transform: scale(1);
        opacity: 1;
        backdrop-filter: grayscale(.4);

        z-index: 10;

        @include tmsTransition(#{"opacity, backdrop-filter"});

        &.inActions {
            top: 3.75rem;
        }

        &.hide {
            transform: scale(0);
            opacity: 0;
            backdrop-filter: grayscale(0);
            z-index: 0;
        }
    }
}