@use "sass:color";

@import "../../../../Theme/ThemeLibrary";

#navSidebar {
    font-size: 1rem;
    line-height: 1.5rem;
    
    position: relative;
    z-index: 1;

    & > main {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 1rem .5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        width: 3.75rem;

        @include tmsTransition(#{"width"});

        .k-button {
            height: 2rem;
            gap: 1rem;
            justify-content: flex-start;
        }

        .k-button-icon { flex: 0 0 1.125rem; }

        .k-button-text {
            @include tmsTransition(#{"opacity"});
            opacity: 0;
        }

        .expandWrap{
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
        }

        .expandBtn {
            width: 2rem;
            justify-content: center;
            opacity: .75;
        }

        @include media-breakpoint-up(sm) {
            padding: 1rem .75rem;
        }
    }

    @each $fmt,$color in $FMTColors {
        &.#{$fmt} {
            $linkFmtColor: scale-color($color, $lightness: -35%, $saturation: 50%);
            
            & > header, & > main {
                color: $linkFmtColor;
            }

            .k-button {
                color: $linkFmtColor;

                &:focus {
                    box-shadow: 0 0 0 0.25rem fade-out($linkFmtColor, .75);
                }
            }
        }
    }

    &.expanded > main {
        width: 12.5rem; // 200px
        .k-button-text { opacity: 1; }
    }
}