@use "sass:map";
@import "../../../../Theme/ThemeLibrary";

#notificationPreview {
    flex: 1 1 auto;
    overflow: auto;
    padding: 0 1rem;

    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
        'title'
        'list';
    
    .title {
        grid-area: title;
        padding: 0.5rem 0;
        display: flex;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        overflow: hidden;

        h3 {
            flex-grow: 1;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                max-width: 160px;
            }
        }
    }

    .notificationList {
        grid-area: list;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            @include font-size(map.get($font-sizes, 6));
            line-height: $line-height-base;
            text-align: center;
            color: $gray-400;
            font-weight: 200;
        }

        .fa-solid {
            @include font-size(map.get($display-font-sizes, 3));
        }
    }
}