@use "sass:map";
@use "sass:math";
@use "sass:color";
@import "../../../Theme/ThemeLibrary";

.launchCard {
    $tamanioLinea: 2.5rem;

    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas:
        'transactionName'
        'transactionId';
    align-items: center;
    justify-content: flex-start;
    
    height: 4rem;

    width: 100%;
    
    @include media-breakpoint-up(sm) {
        width: calc(50% - .5rem);
    }

    @include media-breakpoint-up(lg) {
        width: calc(33% - .52rem);
    }

    @include media-breakpoint-up(xxl) {
        width: calc(20% - .8rem);
    }

    box-shadow: 0 0 .0625rem 0 rgba(0, 0, 0, .25);

    opacity: .8;
    
    @include tmsTransition(#{"background-color, opacity, box-shadow, border-color"});

    .transactionId {
        grid-area: transactionId;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        min-width: $tamanioLinea;

        padding: 0 1rem .25rem;

        font-size: .7rem;
        font-weight: 400;

        height: fit-content;

        span {
            width: fit-content;
            height: 100%;

            display: flex;
            align-items: center;

            background-color: transparent;
            color: $gray-900;

            @include tmsTransition(#{"background-color, color"});
        }
    }

    .transactionName {
        grid-area: transactionName;
        padding: .25rem 1rem 0;
        font-weight: 600;

        @include ellipsisText();
    }

    &:hover {
        opacity: 1;
        cursor: pointer;
        box-shadow: 0 0 .25rem 0 fade-out($black, .75);
    }

    &.over {
        opacity: .15;
    }

    @each $fmt,$color in $FMTColors {
        &[fmt=#{$fmt}] {
            background-color: fade-out($color, .88);
            border-color: fade-out($color, .75);

            .transactionName {
                color: color.scale($color, $lightness: -25%);
            }

            .transactionId span {
                color: $color;
            }

            &:hover {
                background-color: fade-out($color, .8);
                box-shadow: 0 0 .25rem 0 fade-out($color, .65);
                border-color: fade-out($color, .65);
            }
        }    
    }
}