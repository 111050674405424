// Custom values library

// Colours
$Gray1: #FAFAFA;
$Gray2: #E6E6E6;
$Gray3: #DADADA;
$Gray4: #C7C7C7;
$Gray5: #878785;
$Gray6: #656565;
$Gray7: #444;
$Gray8: #333;
$Gray9: #231F20;

$PrimaryColor: #0A69C2;
$SecondaryColor: $Gray2;
$TertiaryColor: #4D98D2;
$InfoColor: #4D98D8;
$OkColor: #53C700;
$WarningColor: #F77B03;
$KoColor: #C7001E;
$BackgroundColor: #FFF;
$TextColor: #000;

$FMTColors: (
    "C": #4d7cb3,
    "F": #ad6252,
    "G": #609d6b,
    "I": #9d6095,
    "R": #c0814e,
    "S": #9d6095,
    "V": #7c8c9d    
);

// Fonts
$FontFamilyBase: "Public Sans", sans-serif;
$FontSizeBase: .9375rem; // 15px