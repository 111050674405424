@import "../../../Theme/ThemeLibrary";

@mixin mainLayout() {    
    html,
    body {
        height: 100vh;
    }

    #root-tms {
        display: flex;
        flex-flow: column;
        height: 100vh;
        
        .wrapper, .content {
            height: 100%;
        }

        .content {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr auto;
            grid-template-areas: "header" "main";

            & > header {
                grid-area: header;
                position: relative;
                z-index: 2;
            }

            & > main {
                grid-area: main;
                overflow: auto;
                display: grid;

                background-color: $gray-100;
            }
        }
        
        #navSidebar {
            min-width: 3.875rem;
            background: $white;
            color: $gray-600;
        
            box-shadow: .125rem 0 .125rem fade-out($black, 0.8);
        
            overflow-y: auto;
            
            display: grid;
        }
    }
}

@mixin toolbarCommonTheme() {
    margin: -0.875rem -1rem 0;
    padding-left: .875rem;
    padding-right: 1rem;
    
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;

    flex-shrink: 0;
    
    &.touch { 
        margin-left: -0.875rem;
        margin-right: -0.875rem;
    }
    
    .delete {
        &::before {
            background: $danger;
        }

        &:hover {
            color: $danger;
        }
    }

    .toolbarLoaderWrap { margin-left: auto; }

    
    &:not(.touch){
        @include media-breakpoint-up(md) {
            padding-top: .3rem;
            padding-bottom: .3rem;
        }
    }
}