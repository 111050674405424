
@use "sass:color";

@import "../../../../Theme/ThemeLibrary";

#userSidebar {
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    
    transform: translate3d(250px, 0, 0);
    width: 250px;

    @include media-breakpoint-up(sm) {        
        transform: translate3d(450px, 0, 0);
        width: 450px;
    }

    @include media-breakpoint-up(lg) {        
        transform: translate3d(550px, 0, 0);
        width: 550px;
    }
    
    background: $white;
    color: $gray-600;

    @include tmsTransition(#{"transform, box-shadow"});
    
    overflow-y: auto;
    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header" "main";
    
    font-size: 1rem;
    line-height: 1.5rem;

    &.active {
        transform: translate3d(0, 0, 0);
        box-shadow: -.1875rem 0 .1875rem fade-out($black, 0.8);
    }

    & > header {
        grid-area: header;
        user-select: none;

        padding: 0.5rem 1rem;
        display: flex;
        gap: 0.5rem;
        gap: 0.5rem;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: flex-end;

        .personCompanyName {
            overflow: hidden;
            flex-direction: column;
        
            h2, h3 {
              @include ellipsisText();
              width: 100%;
              text-align: right;
              margin: 0;
            }
        
            h2 {
              font-weight: 600;
              font-size: .875rem; 
            }
        
            h3 {
                font-size: .6rem;
                opacity: .55;
            }
        }

        .backBtn {
            flex: 0 0 auto;
        }
    }

    & > main {
        grid-area: main;
        overflow: auto;
        padding: 0.5rem 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .userLinks {            
            list-style: none;
            padding: 0 1rem;

            .list-group-item {
                background-color: transparent;
                
                .btn {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }

                .btn-link:hover {
                    text-decoration: none;
                }
            }

            .tmsConfig {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                padding: 0.75rem 0;
                
                i {
                    padding: 0 0.5rem;
                }

                .userCalendar,
                .userConfig {
                    flex-grow: 1;
                    
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .btn {
                        max-width: 40px;
                    }
                }

                .userCalendar {
                    border-right: $list-group-border-width solid $list-group-border-color;                    
                }

                .languages {
                    display: flex;
                    justify-content: space-evenly;
                    flex-grow: 1;

                    .la-language { margin-right: 0.5rem;}
                }

                .userConfig {
                    border-left: $list-group-border-width solid $list-group-border-color;                    
                }
            }

            .logout {
                display: flex;
                justify-content: center;
                
                .k-button {
                    i {
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
}

.languagePopup{
    .k-menu-group .k-item {
        .k-focus {
            box-shadow: none;
        }

        .k-selected {
            color: $primary;
            background-color: fade-out($primary, .9);
        }
    }
}