@import "../../../../../Theme/ThemeLibrary";

#I000000 
{
    display: grid;
    padding: 0;

    .k-card-deck {
        overflow-y: auto;
        padding: 1rem;
        flex-wrap: wrap;
        align-content: start;

        .vehiculos .addedContent {
            text-align: center;

            p {
                margin: 0;
            }

            .contador {
                font-weight: 200;
                @include font-size(3rem);
                line-height: 2.8rem;
            }
            
            .titulo {
                font-size: .75rem;
                font-weight: 600;
            }
        }

        .conductores .addedContent {
            text-align: center;

            p {
                margin: 0;
            }

            .contador {
                font-weight: 200;
                @include font-size(3rem);
                line-height: 2.8rem;
            }
            
            .titulo {
                font-size: .75rem;
                font-weight: 600;
            }
        }

        .licencias .addedContent {
            text-align: center;

            p {
                margin: 0;
            }

            .contador {
                font-weight: 200;
                @include font-size(2.5rem);
                line-height: 2.8rem;
            }
            
            .titulo {
                font-size: .75rem;
                font-weight: 600;
            }
        }

        .revisiones .addedContent {
            text-align: center;

            p {
                margin: 0;
            }

            .contador {
                font-weight: 200;
                @include font-size(2.8rem);
                line-height: 2.8rem;
            }
            
            .titulo {
                font-size: .75rem;
                font-weight: 600;
            }
        }

        .tarjetasConductores .addedContent 
        {
            text-align: center;

            p {
                margin: 0;
            }

            .contador {
                font-weight: 200;
                @include font-size(2.8rem);
                line-height: 2.8rem;
            }
            
            .titulo {
                font-size: .75rem;
                font-weight: 600;
            }
        }

        .ficheros {       
            width: 100%;
        
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        
            @include media-breakpoint-up(lg) {
                width: 33.3%;
            }
        
            @include media-breakpoint-up(xl) {
                width: 23%;
            }
        
            .k-card-title {
                margin: 0;
            }
        
            .k-card-body {
                display: flex;
                flex-direction: column;
            }

            .k-card-body p {
                margin: 0 0 0;
            }
            
            .contentWrapper {
                display: flex;
                flex-direction: column;
        
                .grid-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    width: 100%;
        
                    .row {
                        display: flex;
                        flex-direction: row;
                        gap: 0.5rem;
                        width: 100%;
        
                        .column {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
        
                            h4 {
                                font-size: 0.75rem;
                                font-weight: 600;
                            }
        
                            .contador {
                                font-weight: 200;
                                @include font-size(2rem);
                                line-height: 2.8rem;
        
                                &.erroneos {
                                    color: #C7001E;
                                }
        
                                &.sin-datos {
                                    color: #006DB2
                                }
                            }
                            
                            .titulo {
                                font-size: .75rem;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        &.clickable { cursor: pointer; }
    }

    .mensajeSinDatos {
        align-self: center;
        display: flex;
        justify-content: center;
    }
}