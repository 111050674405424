@import "../Layout/layoutThemeFunctions";

@include mainLayout();

#root-tms {
    .content {
        & > main {
            grid-template-rows: minmax(0, 1fr);
            
            .fallback {
                padding: 1rem;
                position: relative;

                .loaderWrap {
                    position: absolute;
                    top: .75rem;
                    right: 1rem;
                }
            }
        }
    }

    .k-loading-mask {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}