@import "../../../../Theme/ThemeLibrary";

.transactionContainer {
    padding: 1rem;
    position: relative;

    &.cardMode {
        width: calc(100% - 2.1875rem); //35px
        height: calc(100% - 2rem); //32px
        align-self: center;
        justify-self: center;
        border-radius: $border-radius;
        background-color: $white;
        overflow: hidden;
        
        box-shadow: 0 0 .125rem .125rem fade-out($black, 0.85);
    }
}